import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import { useTranslation } from "react-i18next";

init("user_N5ncDpI9yiB87UAl0NmCT");

export default function FormGestante() {
  const { t } = useTranslation(["translation"]);

  function sendEmail(e) {
    e.preventDefault();
    console.log(e.target);
    emailjs
      .sendForm(
        "service_hv7yjcb",
        "template_4nmxwdl",
        e.target,
        "user_N5ncDpI9yiB87UAl0NmCT"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <>
      <h2 className="mt-16 text-xl font-semibold text-color-pink text-center">
        {t("formulario.tituloGestante")}
      </h2>
      <p className="pt-4 font-medium mb-6 text-color-pink text-center">
        {t("formulario.parrafoGestante")}
      </p>
      <div className="w-11/12 m-auto">
        <form onSubmit={sendEmail}>
          <div className="flex flex-col text-gray-500 md:flex-row">
            <div className="mx-6 px-12 pt-6 pb-0 bg-form1 md:w-1/2 shadow-2xl md:pb-6">
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.nombre")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="nombre"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.apellido")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="apellido"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.patronimico")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="patronimico"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.telefonoMovil")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="telefonoMovil"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.telefono")}
                </label>
                <input
                  type="text"
                  name="telefono"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.email")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="email"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.fechaNacimiento")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="fechaNacimiento"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.idiomaQueHabla")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="idiomaQueHabla"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.nacionalidad")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="nacionalidad"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.pais")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="pais"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.ciudad")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="ciudad"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.codigoPostal")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="codigoPostal"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.edad")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="edad"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.altura")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="altura"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.peso")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="peso"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.estadoFamiliar")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="estadoFamiliar"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.texturaPelo")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="texturaPelo"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.colorPelo")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="colorPelo"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.colorOjos")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="colorOjos"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.tez")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="tez"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.origenEtnico")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="origenEtnico"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.judia")}
                </label>
                <select
                  required={true}
                  name="judia"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Немає">{t("formulario.no")}</option>
                  <option value="Так">{t("formulario.si")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.musulmana")}
                </label>
                <select
                  required={true}
                  name="musulmana"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Немає">{t("formulario.no")}</option>
                  <option value="Так">{t("formulario.si")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.edadComienzoCiclo")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="edadComienzoCiclo"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.cicloElMismoDia")}
                </label>
                <select
                  required={true}
                  name="cicloElMismoDia"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Немає">{t("formulario.no")}</option>
                  <option value="Так">{t("formulario.si")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.cicloTodosLosMeses")}
                </label>
                <select
                  required={true}
                  name="cicloTodosLosMeses"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Немає">{t("formulario.no")}</option>
                  <option value="Так">{t("formulario.si")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.diasDeCiclo")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="diasDeCiclo"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.nivelEducacion")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="nivelEducacion"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.estarFueraRusia")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="estarFueraRusia"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.cuantosHijos")}
                </label>
                <select
                  required={true}
                  name="cuantosHijos"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="4">4</option>
                  <option value="+4">+4</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.partosPrematuros")}
                </label>
                <select
                  required={true}
                  name="partosPrematuros"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="+3">+3</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.abortosEspontaneos")}
                </label>
                <select
                  required={true}
                  name="abortosEspontaneos"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="+3">+3</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.cesareas")}
                </label>
                <select
                  required={true}
                  name="cesareas"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="0">0</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                  <option value="3">3</option>
                  <option value="+3">+3</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.defectosNacimiento")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="defectosNacimiento"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
            </div>
            <div className="mx-6 pt-0 pb-6 px-12 bg-form1 md:w-1/2 shadow-2xl md:pt-6">
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.problemasSalud")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="problemasSalud"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.ataqueCorazon")}
                </label>
                <select
                  required={true}
                  name="ataqueCorazon"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Немає">{t("formulario.no")}</option>
                  <option value="Так">{t("formulario.si")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.cancer")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="cancer"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.ictus")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="ictus"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.derrameCerebral")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="derrameCerebral"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.sordo")}
                </label>
                <select
                  required={true}
                  name="sordo"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Ні">{t("formulario.no")}</option>
                  <option value="Так, з народження">
                    {t("formulario.deNacimiento")}
                  </option>
                  <option value="Так, з дитинства">
                    {t("formulario.deNiño")}
                  </option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.complicacionGinecologica")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="complicacionGinecologica"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.cancerOvario")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="cancerOvario"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.ambosOvarios")}
                </label>
                <select
                  required={true}
                  name="ambosOvarios"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Немає">{t("formulario.no")}</option>
                  <option value="Так">{t("formulario.si")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.enfermedadTransmisionSexual")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="enfermedadTransmisionSexual"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.situacionVIH")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="situacionVIH"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.frotisCitologia")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="frotisCitologia"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.tipoSangre")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="tipoSangre"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.fumadoMaria")}
                </label>
                <select
                  required={true}
                  name="fumadoMaria"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Немає">{t("formulario.no")}</option>
                  <option value="Так">{t("formulario.si")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.teHasDrogado")}
                </label>
                <select
                  required={true}
                  name="teHasDrogado"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Немає">{t("formulario.no")}</option>
                  <option value="Так">{t("formulario.si")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.pruebaIQ")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="pruebaIQ"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.tiempoAnticonceptivos")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="tiempoAnticonceptivos"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.cirugiaPlastica")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="cirugiaPlastica"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.ligaduraTrompas")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="ligaduraTrompas"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.gradoBronceado.titulo")}
                </label>
                <select
                  required={true}
                  name="gradoBronceado"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="apenas bronceado">{t("formulario.gradoBronceado.apenasBronceado")}</option>
                  <option value="moderado">{t("formulario.gradoBronceado.moderado")}</option>
                  <option value="con rapidez">{t("formulario.gradoBronceado.conRapidez")}</option>
                  <option value="muy rapido">{t("formulario.gradoBronceado.muyRapido")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.experienciaGestando")}
                </label>
                <select
                  required={true}
                  name="experienciaGestando"
                  className="mt-1 w-full py-1 px-3 bg-white shadow-sm focus:outline-none  sm:text-sm"
                >
                  <option value="Немає">{t("formulario.no")}</option>
                  <option value="Так">{t("formulario.si")}</option>
                </select>
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.trabajas")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="trabajas"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.desplazarseGestante")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="desplazarseGestante"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="">
                <label className="font-medium text-color-pink text-justify">
                  {t("formulario.mudarseGestante")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="mudarseGestante"
                  className="mt-1 w-full shadow-sm py-1 px-3 sm:text-sm"
                />
              </div>
              <div className="bg-white my-4">
                <fieldset>
                  <legend className="text-base font-medium bg-form1 text-color-pink text-justify">
                    {t("formulario.parejasQueAceptas.titulo")}
                  </legend>
                  <div className="mt-4 pb-3 px-4 space-y-3">
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          name="sinLimite"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-500">
                          {t("formulario.parejasQueAceptas.sinLimite")}
                        </label>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          name="matrimonioHetero"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-500">
                          {t("formulario.parejasQueAceptas.matrimonioHetero")}
                        </label>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          name="heterosNoCasados"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-500">
                          {t("formulario.parejasQueAceptas.heterosNoCasados")}
                        </label>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          name="homosexual"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-500">
                          {t("formulario.parejasQueAceptas.homosexual")}
                        </label>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          name="mujeresHetero"
                          type="checkbox"
                          className="h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-500">
                          {t("formulario.parejasQueAceptas.mujeresHeteros")}
                        </label>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          name="hombresHetero"
                          type="checkbox"
                          className=" h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-500">
                          {t("formulario.parejasQueAceptas.hombresHeteros")}
                        </label>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          name="parejasMasculinas"
                          type="checkbox"
                          className=" h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-500">
                          {t("formulario.parejasQueAceptas.parejasMasculinas")}
                        </label>
                      </div>
                    </div>
                    <div className="flex items-start">
                      <div className="flex items-center h-5">
                        <input
                          name="lesbianas"
                          type="checkbox"
                          className=" h-4 w-4 text-indigo-600 border-gray-300 rounded"
                        />
                      </div>
                      <div className="ml-3 text-sm">
                        <label className="font-medium text-gray-500">
                          {t("formulario.parejasQueAceptas.lesbiana")}
                        </label>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </div>
              <div className="m-auto">
                <label className="font-medium text-color-pink">
                  {t("formulario.agregaFoto")}
                </label>
                <div className="mt-1 flex justify-center px-6 py-8 border-2 border-gray-300 bg-white border-dashed">
                  <div className="space-y-1 text-center">
                    <div className="flex text-gray-600">
                      <label className="relative cursor-pointer font-medium text-color-pink focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-">
                        <span>Upload a file</span>
                        <input
                          required={true}
                          name="foto"
                          type="file"
                          className="sr-only"
                        />
                      </label>
                      <p className="pl-1">or drag and drop</p>
                    </div>
                    <p className="text-xs">PNG, JPG, GIF up to 10MB</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <fieldset className="">
            <div className="flex items-start py-3">
              <div className="flex items-center h-5">
                <input
                  required={true}
                  name="consentimiento"
                  type="checkbox"
                  className="focus:ring-color-pink h-4 w-4 text-color-pink border-gray-300 rounded"
                />
              </div>
              <div className="ml-3 text-sm">
                <label className="text-base font-medium text-gray-900">
                  <p className="text-justify">
                    {t("formulario.consentimientoGestante")}
                  </p>
                </label>
              </div>
            </div>
          </fieldset>
          <div className="px-4 py-3 bg-gray-50 text-center sm:px-6">
            <input
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm font-medium text-white bg-color-pink focus:outline-none focus:ring-2 focus:ring-offset-2"
              value={t("formulario.enviar")}
            />
          </div>
        </form>
      </div>
    </>
  );
}
