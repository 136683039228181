import { useTranslation } from "react-i18next";

import FormDonante from "../components/Donante";

import ovulos from "../images/ovulos.webp";
import doctoraYMujer from "../images/doctora-y-mujer.webp";


export default function ComoSerDonante() {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <h2 className="text-2xl mt-8 font-semibold text-color-pink text-center md:text-3xl lg:text-4xl">
        {t("paginas.comoSerDonante.titulo")}
      </h2>
      <p className="m-4 text-color-pink text-justify md:mx-8 lg:text-lg lg:text-center">
        {t("paginas.comoSerDonante.parrafo1")}
      </p>
      <p className="text-color-pink mx-4 text-justify md:mx-8 lg:text-lg lg:text-center">
        {t("paginas.comoSerDonante.parrafo2")}
      </p>
      <div className="bg-bg-gray mt-10 py-20">
        <div className="ml-[10%] mr-[10%] lg:flex">
          <div className="m-auto lg:mr-[10%]">
            <img src={ovulos} alt="ovulos" style={{width:"350px", height:"230px"}} class="m-auto" />
          </div>
          <div className="text-white space-y-2">
            <p className="text-xl font-semibold mt-4 sm:text-2xl">
              {t("paginas.comoSerDonante.requisitos.titulo")}
            </p>
            <ul className="list-disc list-inside ml-4 space-y-2 lg:text-xl">
              <li>{t("paginas.comoSerDonante.requisitos.edad")}</li>
              <li>{t("paginas.comoSerDonante.requisitos.aparienciaFisica")}</li>
              <li>
                {t("paginas.comoSerDonante.requisitos.antecedentesGinecologicos")}
              </li>
              <li>
                {t("paginas.comoSerDonante.requisitos.antecedentesMedicos")}
              </li>
              <li>{t("paginas.comoSerDonante.requisitos.enfermedadSexual")}</li>
              <li>{t("paginas.comoSerDonante.requisitos.tenidoRelaciones")}</li>
            </ul>
          </div>
        </div>
      </div>
      <div className="my-20 w-9/12 m-auto lg:flex">
        <div className="my-auto pr-0 lg:w-1/2 lg:pr-12">
          <p className="text-xl font-medium mt-4 xl:text-2xl">
            {t("paginas.comoSerDonante.titulo2")}
          </p>
          <p className="my-4 text-justify xl:text-lg">
            {t("paginas.comoSerDonante.parrafo3")}
          </p>
        </div>
        <div className="m-auto pl-0 lg:w-1/2 lg:pl-12">
          <img src={doctoraYMujer} style={{width:"100%"}} alt="doctora y mujer embarazada" className="2xl:w-5/6" />
        </div>
      </div>
      <div className="md:flex">
        <div className="py-10 bg-color-pink text-white md:w-1/2">
          <div className="w-9/12 m-auto">
            <p className="text-2xl font-medium mt-4">
              {t("paginas.comoSerDonante.titulo3")}
            </p>
            <p className="my-4 text-lg text-justify">
              {t("paginas.comoSerDonante.parrafo4")}
            </p>
          </div>
        </div>
        <div className="py-10 bg-bg-gray text-color-pink md:w-1/2">
          <div className="w-9/12 m-auto">
            <p className="text-2xl font-medium mt-4">
              {t("paginas.comoSerDonante.pruebasRequeridas.titulo")}
            </p>
            <ul className="list-disc list-inside text-lg ml-4">
              <li className="text-justify">
                {t("paginas.comoSerDonante.pruebasRequeridas.revisionGinecologica")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerDonante.pruebasRequeridas.analisisSangre")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerDonante.pruebasRequeridas.electrocardiologia")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerDonante.pruebasRequeridas.antecedentesMedicos")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerDonante.pruebasRequeridas.testPsicologico")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerDonante.pruebasRequeridas.documentoInformativo")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerDonante.pruebasRequeridas.fotoVideo")}
              </li>
            </ul>
          </div>
        </div>
      </div>
      <FormDonante />
    </>
  );
}
