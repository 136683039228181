import { useTranslation } from "react-i18next";

import FormContacto from "../components/Contacto";

import albania from "../images/ALBANIA.webp";
import bcn from "../images/BCN.webp";
import delaware from "../images/DELAWARE.webp";
import florida from "../images/FLORIDA.webp";
import grecia from "../images/GRECIA.webp";
import kiev from "../images/KIEV.webp";

export default function Contacto() {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <div className="h-[45rem] bg-smartphone bg-cover bg-[center_bottom_-1rem] bg-no-repeat">
        <div className="relative top-[550px]">
          <div className="w-3/4 m-auto">
            <FormContacto />
          </div>
        </div>
      </div>
      <div className="mt-96 flex flex-col md:flex-row sm:mt-72">
        <div className="m-auto flex flex-col md:w-1/2">
          <div className="md:ml-[10%] md:mr-[5%]">
            <div className="flex mb-8">
              <img
                src={kiev}
                alt="Kiev"
                style={{width:"128px", height:"128px"}}
                className="my-auto ml-5 mr-2"
              />
              <div className="mr-5">
                <h4 className="text-gray-700 text-lg font-semibold">
                  {t("paginas.contacto.kiev.oficina")}
                </h4>
                <p>{t("paginas.contacto.kiev.ciudad")}</p>
                <p>{t("paginas.contacto.kiev.ubi")}</p>
                <p>{t("paginas.contacto.kiev.codigo")}</p>
                <p>{t("paginas.contacto.kiev.pais")}</p>
                <p>Tel: +380 50 417 73 60</p>
              </div>
            </div>
            <div className="flex mb-8">
              <img
                src={florida}
                alt="Florida"
                style={{width:"128px", height:"128px"}}
                className="my-auto ml-5 mr-2"
              />
              <div className="mr-5">
                <h4 className="text-gray-700 text-lg font-semibold">
                  {t("paginas.contacto.florida.oficina")}
                </h4>
                <p>{t("paginas.contacto.florida.ciudad")}</p>
                <p>{t("paginas.contacto.florida.ubi")}</p>
                <p>{t("paginas.contacto.florida.codigo")}</p>
                <p>{t("paginas.contacto.florida.pais")}</p>
              </div>
            </div>
            <div className="flex mb-8">
              <img
                src={bcn}
                alt="Barcelona"
                style={{width:"128px", height:"128px"}}
                className="my-auto ml-5 mr-2"
              />
              <div className="mr-5">
                <h4 className="text-gray-700 text-lg font-semibold">
                  {t("paginas.contacto.barcelona.oficina")}
                </h4>
                <p>{t("paginas.contacto.barcelona.ciudad")}</p>
                <p>{t("paginas.contacto.barcelona.ubi")}</p>
                <p>{t("paginas.contacto.barcelona.codigo")}</p>
                <p>{t("paginas.contacto.barcelona.pais")}</p>
                <p>{t("paginas.contacto.barcelona.noGestacion")}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="m-auto flex flex-col md:w-1/2">
          <div className="md:mr-[10%] md:ml-[5%]">
            <div className="flex mb-8">
              <img
                src={grecia}
                alt="Grecia"
                style={{width:"128px", height:"128px"}}
                className="my-auto ml-5 mr-2"
              />
              <div className="mr-5">
                <h4 className="text-gray-700 text-lg font-semibold">
                  {t("paginas.contacto.tesalonica.oficina")}
                </h4>
                <p>{t("paginas.contacto.tesalonica.ciudad")}</p>
                <p>{t("paginas.contacto.tesalonica.ubi")}</p>
                <p>{t("paginas.contacto.tesalonica.codigo")}</p>
                <p>{t("paginas.contacto.tesalonica.pais")}</p>
                <p>{t("paginas.contacto.tesalonica.noGestacion")}</p>
              </div>
            </div>
            <div className="flex mb-8">
              <img
                src={delaware}
                alt="Delaware"
                style={{width:"128px", height:"128px"}}
                className="my-auto ml-5 mr-2"
              />
              <div className="mr-5">
                <h4 className="text-gray-700 text-lg font-semibold">
                  {t("paginas.contacto.delaware.oficina")}
                </h4>
                <p>{t("paginas.contacto.delaware.ciudad")}</p>
                <p>{t("paginas.contacto.delaware.ubi")}</p>
                <p>{t("paginas.contacto.delaware.codigo")}</p>
                <p>{t("paginas.contacto.delaware.pais")}</p>
              </div>
            </div>
            <div className="flex mb-8">
              <img
                src={albania}
                alt="Albania"
                style={{width:"128px", height:"128px"}}
                className="my-auto ml-5 mr-2"
              />
              <div className="mr-5">
                <h4 className="text-gray-700 text-lg font-semibold">
                  {t("paginas.contacto.albania.oficina")}
                </h4>
                <p>{t("paginas.contacto.albania.ciudad")}</p>
                <p>{t("paginas.contacto.albania.ubi")}</p>
                <p>{t("paginas.contacto.albania.codigo")}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
