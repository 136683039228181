import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import whatsapp from "../images/whatsapp.webp";
import instagram from "../images/instagram.webp";
import facebook from "../images/facebook.webp";
import email from "../images/email.webp";
import menu from "../images/menu.webp";
import logo from "../images/logo.webp";
import geo from "../images/georgia.webp";
import ukr from "../images/ucrania.webp";

export default function ComoSerDonante() {
  const { t, i18n } = useTranslation(["translation"]);

  function changeToUcraniano() {
    i18n.changeLanguage("ukr");
  }
  function changeToGeorgiano() {
    i18n.changeLanguage("ge");
  }

  let [open, setOpen] = useState(false);

  return (
    <header className="">
      <navbar className="flex bg-neutral-200">
        <div className="w-10/12 m-auto flex text-xs py-1">
          <div className="hidden md:flex text-color-pink">
            <a
              href="https://api.whatsapp.com/send?phone=380504177360"
              rel="noopener noreferrer"
              target="_blank"
              className="flex m-auto mr-16"
            >
              <img src={whatsapp} alt="whatasapp logo" className="mr-2" style={{width:"18px", height:"18px"}} />
              <p>+380 50 417 73 60</p>
            </a>
            <a href="mailto:sm@surrogestlife.com" className="flex m-auto">
              <img src={email} alt="email logo" className="mr-2" style={{width:"18px", height:"18px"}} />
              <p>sm@surrogestlife.com</p>
            </a>
          </div>
          <div className="flex mx-auto mt-1">
            <a
              href="https://api.whatsapp.com/send?phone=380504177360"
              rel="noopener noreferrer"
              target="_blank"
              className="mr-2"
            >
              <img src={whatsapp} alt="whatasapp logo" style={{width:"20px", height:"20px"}} />
            </a>
            <a 
              href="mailto:sm@surrogestlife.com"
              rel="noopener noreferrer"
              target="_blank" 
              className="ml-2"
            >
              <img src={email} alt="email logo" style={{width:"20px", height:"20px"}} className="mr-2" />
            </a>
            <a
              href="https://www.facebook.com/surrogestlifeUA/"
              rel="noopener noreferrer"
              target="_blank"
              className="mx-2"
            >
              <img src={facebook} alt="facebook logo" style={{width:"20px", height:"20px"}} />
            </a>
            <a
              href="https://www.instagram.com/surrogestlifeua/"
              rel="noopener noreferrer"
              target="_blank"
              className="ml-2"
            >
              <img src={instagram} alt="instagram logo" style={{width:"20px", height:"20px"}} />
            </a>
          </div>
          <div className="my-auto">
            <button onClick={changeToUcraniano} className="mr-3">
              <img src={ukr} alt="bandera UKR" style={{width:"28px", height:"28px"}} />
            </button>
            <button onClick={changeToGeorgiano}>
              <img src={geo} alt="bandera GEO" style={{width:"28px", height:"28px"}} />
            </button>
          </div>
        </div>
      </navbar>

      <div className="shadow-sm">
        <div className="lg:top-0 flex items-center justify-center bg-white py-1 lg:px-10 px-7">
          <div className="font-bold text-2xl cursor-pointer">
            <NavLink to="/" className="w-40">
              <img
                src={logo}
                style={{width:"160px", height:"36px"}}
                className="2xl:left-[-260px] xl:left-[-120px] lg:relative left-[-10px] my-3"
                alt="logo"
              ></img>
            </NavLink>
          </div>
          <div
            onClick={() => setOpen(!open)}
            className="absolute right-8 top-15 cursor-pointer lg:hidden"
          >
            <img src={menu} alt="menu" name={open} style={{width:"28px", height:"20px"}} />
          </div>
          <ul
            className={`lg:flex lg:items center lg:pb-0 pb-2 absolute lg:static bg-white lg:z-auto z-[1] left-0 w-full lg:w-auto lg:pl-0 pl-9 transition-all duration-500 ease-in ${
              open ? "top-24" : "top-[-500px]"
            }`}
          >
            <li className="lg:ml-8 lg:my-0 my-7">
              <NavLink
                to="/quiero-ser-gestante"
                className="text-gray-700 hover:text-gray-400 duration-500"
              >
                {t("header.quieroSerGestante")}
              </NavLink>
            </li>
            <li className="lg:ml-8 lg:my-0 my-7">
              <NavLink
                to="/quiero-ser-donante"
                className="text-gray-700 hover:text-gray-400 duration-500"
              >
                {t("header.quieroSerDonante")}
              </NavLink>
            </li>
            <li className="lg:ml-8 lg:my-0 my-7">
              <NavLink
                to="/contacto"
                className="text-gray-700 hover:text-gray-400 duration-500"
              >
                {t("header.contacto")}
              </NavLink>
            </li>
            <li className="lg:ml-8 lg:my-0 my-7">
              <NavLink
                to="/FAQS"
                className="text-gray-700 hover:text-gray-400 duration-500"
              >
                {t("header.faqs")}
              </NavLink>
            </li>
          </ul>
        </div>
      </div>
    </header>
  );
}
