import emailjs from "emailjs-com";
import { init } from "emailjs-com";
import { useTranslation } from "react-i18next";

init("user_N5ncDpI9yiB87UAl0NmCT");

export default function ComoSerDonante() {
  const { t } = useTranslation(["translation"]);

  function sendEmail(e) {
    e.preventDefault();
    console.log(e.target);
    emailjs
      .sendForm(
        "service_hv7yjcb",
        "template_4zmi8ji",
        e.target,
        "user_N5ncDpI9yiB87UAl0NmCT"
      )
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
  }

  return (
    <div className="mt-5 px-5 md:mt-0 w-full  md:col-span-2">
      <form onSubmit={sendEmail} autocomplete="off">
        <div className="shadow-xl sm:overflow-hidden">
          <div className="py-5 bg-form1 sm:p-6">
            <div className=" px-4 grid grid-cols-6 gap-6">
              <div className="col-span-3 sm:col-span-3">
                <label className="block text-base font-medium text-color-pink text-justify">
                {t("formulario.nombre")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="nombre"
                  className="mt-1 py-1 px-2 block w-full shadow-sm sm:text-base"
                />
              </div>

              <div className="col-span-3 sm:col-span-3">
                <label className="block text-base font-medium text-color-pink text-justify">
                {t("formulario.apellido")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="apellido"
                  className="mt-1 py-1 px-2 block w-full shadow-sm sm:text-base"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label className="block text-base font-medium text-color-pink text-justify">
                {t("formulario.telefonoMovil")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="telefonoMovil"
                  className="mt-1 py-1 px-2 block w-full shadow-sm sm:text-base"
                />
              </div>

              <div className="col-span-6 sm:col-span-3">
                <label className="block text-base font-medium text-color-pink text-justify">
                {t("formulario.email")}
                </label>
                <input
                  required={true}
                  type="text"
                  name="email"
                  className="mt-1 py-1 px-2 block w-full shadow-sm sm:text-base"
                />
              </div>

              <div className="col-span-6 sm:col-span-6">
                <label className="block text-base font-medium text-color-pink text-justify">
                {t("formulario.mensaje")}
                </label>
                <textarea
                  required={true}
                  type="text"
                  rows="4"
                  name="mensaje"
                  className="mt-1 py-1 px-2 block w-full shadow-sm sm:text-base"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="px-4 pt-8 text-center sm:px-6">
          <input
            type="submit"
            className="inline-flex justify-center mb-10 py-2 px-4 border border-transparent shadow-sm text-base font-medium rounded-md text-white bg-color-pink focus:outline-none focus:ring-2 focus:ring-offset-2 "
            value={t("formulario.enviar")}
          />
        </div>
      </form>
    </div>
  );
}
