import React, { Suspense } from "react";
import { HashRouter as Router, Routes, Route } from "react-router-dom";

import ComoSerDonante from "./pages/ComoSerDonante";
import ComoSerGestante from "./pages/ComoSerGestante";
import QuienesSomos from "./pages/QuienesSomos";
import PageNotFound from "./pages/PageNotFound";
import Contacto from "./pages/Contacto";
import FAQS from "./pages/FAQS";

import Header from "./components/Header";
import Footer from "./components/Footer";

const Welcome = () => {
  return (
    <>
      <Routes>
        <Route path="/FAQS" element={<FAQS />} />
        <Route path="/contacto" element={<Contacto />} />
        <Route path="/quiero-ser-gestante" element={<ComoSerGestante />} />
        <Route path="/quiero-ser-donante" element={<ComoSerDonante />} />
        <Route path="*" element={<PageNotFound />} />
        <Route path="/" element={<QuienesSomos />} />
      </Routes>
    </>
  );
};

const App = () => {
  return (
    <Router>
      <Suspense fallback="Loading...">
        <Header />
        <Welcome />
        <Footer />
      </Suspense>
    </Router>
  );
};

export default App;
