import { useTranslation } from "react-i18next";

import FormGestante from "../components/Gestante";

import mujerEcografia from "../images/mujer-ecografia.webp"

export default function ComoSerMadre() {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <div className="font-roboto">
        <div className="bg-doctor-y-mujer h-[200px] w-full bg-cover bg-no-repeat bg-top md:h-96"></div>

        <div className="md:flex">
          <div className="pr-[5%] pl-[5%] pt-14 bg-bg-blue md:w-1/2">
            <h2 className="text-2xl font-semibold text-white md:text-4xl">
              {t("paginas.comoSerGestante.titulo")}
            </h2>
            <p className="mt-4 text-justify text-white">
              {t("paginas.comoSerGestante.parrafo1")}
            </p>
            <p className="mt-4 text-justify text-white">
              {t("paginas.comoSerGestante.parrafo2")}
            </p>
            <p className="mt-4 text-justify text-white">
              {t("paginas.comoSerGestante.parrafo3")}
            </p>
            <p className="text-lg font-semibold mt-4 text-white">
              {t("paginas.comoSerGestante.requisitos.titulo")}
            </p>
            <ul className="list-disc ml-4 text-white">
              <li>
                {t("paginas.comoSerGestante.requisitos.edad")}
              </li>
              <li>
                {t("paginas.comoSerGestante.requisitos.sana")}
              </li>
              <li>
                {t("paginas.comoSerGestante.requisitos.noDrogas")}
              </li>
            </ul>
          </div>
          <div className="pr-[5%] pl-[5%] pt-14 pb-6 bg-bg-gray md:w-1/2">
            <h2 className="text-xl font-semibold text-bg-blue md:text-2xl">
              {t("paginas.comoSerGestante.titulo2")}
            </h2>
            <p className="my-4 text-justify text-bg-blue">
              {t("paginas.comoSerGestante.parrafo4")}
            </p>
            <p className="text-lg font-medium mt-4 text-bg-blue">
              {t("paginas.comoSerGestante.nosPreocupamos.titulo")}
            </p>
            <ul className="list-disc ml-4">
              <li className="text-justify text-bg-blue">
                {t("paginas.comoSerGestante.nosPreocupamos.abogado")}
              </li>
              <li className="text-justify text-bg-blue">
                {t("paginas.comoSerGestante.nosPreocupamos.asesoramiento")}
              </li>
              <li className="text-justify text-bg-blue">
                {t("paginas.comoSerGestante.nosPreocupamos.seguimientoMedico")}
              </li>
              <li className="text-justify text-bg-blue">
                {t("paginas.comoSerGestante.nosPreocupamos.apoyoPsicologico")}
              </li>
              <li className="text-justify text-bg-blue">
                {t("paginas.comoSerGestante.nosPreocupamos.buenTrato")}
              </li>
            </ul>
          </div>
        </div>
        <div className="w-10/12 my-16 mx-auto flex text-bg-blue">
          <img src={mujerEcografia} alt="mujer ecografia" style={{width:"30%", height:"15%"}} className="hidden sm:block top-10 sticky mr-12" />
          <div className="">
            <h2 className="text-xl font-semibold md:text-3xl">
              {t("paginas.comoSerGestante.titulo3")}
            </h2>
            <p className="my-4 text-justify">
              {t("paginas.comoSerGestante.parrafo5")}
            </p>
            <p className="text-justify">
              {t("paginas.comoSerGestante.parrafo6")}
            </p>
            <p className="my-4 text-justify">
              {t("paginas.comoSerGestante.parrafo7")}
            </p>
            <p className="text-justify">
              {t("paginas.comoSerGestante.parrafo8")}
            </p>
            <p className="my-4 text-justify">
              {t("paginas.comoSerGestante.parrafo9")}
            </p>
            <p className="text-justify">
              {t("paginas.comoSerGestante.parrafo10")}
            </p>
            <p className="my-4 text-justify">
              {t("paginas.comoSerGestante.parrafo11")}
            </p>
            <p className="text-justify">
              {t("paginas.comoSerGestante.parrafo12")}
            </p>
            <p className="my-4 text-justify">
              {t("paginas.comoSerGestante.parrafo13")}
            </p>
            <p className="text-lg font-semibold mt-4">
              {t("paginas.comoSerGestante.empiezaPrograma.titulo")}
            </p>
            <ul className="list-disc ml-4">
              <li className="text-justify">
                {t("paginas.comoSerGestante.empiezaPrograma.transfusion")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerGestante.empiezaPrograma.seguimientoEmbarazo")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerGestante.empiezaPrograma.facilitaciones")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerGestante.empiezaPrograma.ayudaEconomica")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerGestante.empiezaPrograma.firmaRenuncia")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerGestante.empiezaPrograma.compensacionAcordada")}
              </li>
              <li className="text-justify">
                {t("paginas.comoSerGestante.empiezaPrograma.seguimientoMedico")}
              </li>
            </ul>
          </div>
        </div>
        <div className="h-60 bg-doctora-y-gestante bg-no-repeat bg-fixed bg-cover block bg-[center_bottom_-0px] lg:h-80 xl:h-96"></div>
        <FormGestante />
      </div>
    </>
  );
}
