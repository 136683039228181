import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";

import FormContacto from "../components/Contacto";

export default function QuienesSomos() {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <div className="">
        <div className="h-96 bg-zapatosBebe bg-no-repeat bg-[center_-15rem] bg-fixed bg-cover block">

        </div>
        <div className="w-9/12 m-auto">
          <h1 className="text-2xl mt-6 text-center font-semibold text-color-pink pb-4">
            {t("paginas.quienesSomos.titulo")}
          </h1>
          <p className="text-center text-lg text-color-pink font-medium">
            {t("paginas.quienesSomos.texto")}
          </p>
          <h2 className="text-xl mt-8 text-center font-semibold text-color-pink pb-4">
            {t("paginas.comoSerGestante.titulo")}
          </h2>
          <p className="text-center text-lg text-color-pink">
            {t("paginas.comoSerGestante.parrafo1")}
          </p>
          <div className="px-4 py-3 text-center sm:px-6">
            <NavLink
              to="/quiero-ser-gestante"
              className="inline-flex justify-center mb-6 py-2 px-4 text-sm font-medium rounded-md text-white bg-color-pink focus:outline-none focus:ring-2 focus:ring-offset-2 "
            >
              {t("paginas.quienesSomos.saberMas")}
            </NavLink>
          </div>
          <h2 className="text-xl text-center font-semibold text-color-pink pb-4">
            {t("paginas.comoSerDonante.titulo")}
          </h2>
          <p className="text-center text-lg text-color-pink">
            {t("paginas.comoSerDonante.parrafo1")}
          </p>
          <div className="px-4 py-3 text-center sm:px-6">
            <NavLink
              to="/quiero-ser-donante"
              className="inline-flex justify-center mb-8 py-2 px-4 text-sm font-medium rounded-md text-white bg-color-pink focus:outline-none focus:ring-2 focus:ring-offset-2 "
            >
              {t("paginas.quienesSomos.saberMas")}
            </NavLink>
          </div>
        </div>
        
      </div>
      <div className="w-10/12 m-auto">
        <FormContacto />
      </div>

      <div className="h-96 bg-ecografia bg-no-repeat bg-cover block md:bg-[center_-10rem]"></div>
    </>
  );
}
