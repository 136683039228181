import { useTranslation } from "react-i18next";

export default function FAQS() {
  const { t } = useTranslation(["translation"]);

  return (
    <>
      <div className="w-10/12 m-auto text-color-pink mb-16">
        <h2 className="my-8 text-2xl font-semibold text-center md:text-4xl">
          {t("paginas.faqs.titulo")}
        </h2>
      </div>

      <div class="w-11/12 m-auto text-color-pink font-semibold text-lg pb-16 md:w-8/12">
        <div class="space-y-6">
          <details class="py-3 bg-form1 hover:cursor-pointer shadow-xl peer">
            <summary class="flex justify-between items-center text-summary px-5">
              {t("paginas.faqs.preg1")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline-block float-right mt-2 mr-2 cursor-pointer hover:rotate-45"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </summary>
            <p class="font-normal px-5 pt-2 transition-all ease-in-out duration-1000">
              {t("paginas.faqs.res1")}
            </p>
          </details>
          <details class="py-3 bg-form1 hover:cursor-pointer shadow-xl">
            <summary class="flex justify-between items-center text-summary px-5">
              {t("paginas.faqs.preg2")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline-block float-right mt-2 mr-2 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </summary>
            <p class="font-normal px-5 pt-2">{t("paginas.faqs.res2")}</p>
          </details>
          <details class="py-3 bg-form1 hover:cursor-pointer shadow-xl">
            <summary class="flex justify-between items-center text-summary px-5">
              {t("paginas.faqs.preg3")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline-block float-right mt-2 mr-2 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </summary>
            <p class="font-normal px-5 pt-2">{t("paginas.faqs.res3")}</p>
          </details>
          <details class="py-3 bg-form1 hover:cursor-pointer shadow-xl">
            <summary class="flex justify-between items-center text-summary px-5">
              {t("paginas.faqs.preg4")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline-block float-right mt-2 mr-2 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </summary>
            <p class="font-normal px-5 pt-2">{t("paginas.faqs.res4")}</p>
          </details>
          <details class="py-3 bg-form1 hover:cursor-pointer shadow-xl">
            <summary class="flex justify-between items-center text-summary px-5">
              {t("paginas.faqs.preg5")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline-block float-right mt-2 mr-2 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </summary>
            <p class="font-normal px-5 pt-2">{t("paginas.faqs.res5")}</p>
          </details>
          <details class="py-3 bg-form1 hover:cursor-pointer shadow-xl">
            <summary class="flex justify-between items-center text-summary px-5">
              {t("paginas.faqs.preg6")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline-block float-right mt-2 mr-2 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </summary>
            <p class="font-normal px-5 pt-2">{t("paginas.faqs.res6")}</p>
          </details>
          <details class="py-3 bg-form1 hover:cursor-pointer shadow-xl">
            <summary class="flex justify-between items-center text-summary px-5">
              {t("paginas.faqs.preg7")}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                class="h-5 w-5 inline-block float-right mt-2 mr-2 cursor-pointer"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
                stroke-width="4"
              >
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  d="M12 4v16m8-8H4"
                />
              </svg>
            </summary>
            <p class="font-normal px-5 pt-2">{t("paginas.faqs.res7")}</p>
          </details>
        </div>
      </div>
    </>
  );
}
