import { useTranslation } from "react-i18next";

import whatsapp from "../images/whatsapp.webp";
import instagram from "../images/instagram.webp";
import facebook from "../images/facebook.webp";
import email from "../images/email.webp";
import telefono from "../images/telefono.webp";

export default function ComoSerDonante() {

  const { t } = useTranslation(["translation"]);

  return (
    <footer id="footer" className="flex flex-col bg-neutral-200">
      <div className="w-10/12 m-auto">
        <p className="mt-6 mb-5 text-sm text-justify font-medium">
        {t("footer.tuEresImportante")}
        </p>
        <div className="flex flex-col text-center text-sm">
          <p className="text-color-pink">© 2022 SurroGestLife - Invest Medical</p>
          <div className="flex flex-col m-auto sm:flex-row">
            <div className="flex mr-4">
              <img src={telefono} alt="Telefono" style={{width:"15px", height:"20px"}} className="mt-1 mr-2"></img>
              <p className="text-sm sm:text-base">+380 50 417 73 60</p>
            </div>
            <a href="mailto:sm@surrogestlife.com" className="flex">
              <img src={email} alt="email logo" style={{width:"20px", height:"20px"}} className="my-auto mr-2" />
              <p className="text-sm sm:text-base">sm@surrogestlife.com</p>
            </a>
          </div>
          <div className="flex m-auto mt-3 mb-8">
            <a
              href="https://api.whatsapp.com/send?phone=380504177360"
              rel="noopener noreferrer"
              target="_blank"
              className="mr-2"
            >
              <img src={whatsapp} alt="whatasapp logo" style={{width:"16px", height:"16px"}} />
            </a>
            <a
              href="https://www.facebook.com/surrogestlifeUA/"
              rel="noopener noreferrer"
              target="_blank"
              className="mx-2"
            >
              <img src={facebook} alt="facebook logo" style={{width:"16px", height:"16px"}} />
            </a>
            <a
              href="https://www.instagram.com/surrogestlifeua/"
              rel="noopener noreferrer"
              target="_blank"
              className="ml-2"
            >
              <img src={instagram} alt="instagram logo" style={{width:"16px", height:"16px"}} />
            </a>
          </div>
        </div>
      </div>
    </footer>
  );
}
